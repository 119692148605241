$(document).ready(function(){
  $('#sidebarCollapse').on('click', function () {
    toggleSidebar();
  });

  $('#overlay').on('click', function () {
    toggleSidebar();
  });

  $('#sidebarDropdownMenu > a').on('click', function () {
    toggleSidebar();
  });

  $('#sidebarButtonAdd > a').on('click', function () {
    toggleSidebar();
  });

  $('.nav.flex-column.mb-4 > li > a').on('click', function () {
    toggleSidebar();
  });

  $('.nav.flex-column > li > ul > li').on('click', function () {
    toggleSidebar();
  });

  document.body.addEventListener('ajax:send', function(event) {
    $('#loading').fadeIn(200);
  })
  document.body.addEventListener('ajax:complete', function(event) {
    $('#loading').fadeOut(200);
  });
});


$(function () {
    $('div.dropdown-menu a[data-remote=true]').click(function () {
        $(this).closest('div').prev('button').dropdown('toggle');
    });
});

export function toggleSidebar() {
  $('#sidebar').toggleClass('active');
  $('#overlay').toggleClass('active');
}


/*
$('.nav a').on('click', function(){
  if($(this).attr('class') != "dropdown-toggle") {
    if($(".navbar .navbar-toggle").css('display') !='none'){
      $(".navbar .navbar-toggle").click();
    }
  }
});
*/
