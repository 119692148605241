import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "ul" ]

  connect() {

  }

  toggleSubmenu() {
    let item = event.target;
    setTimeout(() => {
      Cookies.setItem(item.id, item.getAttribute('aria-expanded'));
    }, 150);
  }
}
